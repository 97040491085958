var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('popup-modal',{ref:"popup"},[[_c('v-btn',{staticStyle:{"right":"12px","top":"-12px","float":"left"},attrs:{"fab":"","dark":"","small":"","color":"blue-grey","title":"Закрыть окно"},on:{"click":function($event){return _vm.closePopup()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1),(_vm.info)?_c('v-simple-table',{staticStyle:{"max-height":"400px","min-width":"300px"},attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Наименование")])])]),_c('tbody',_vm._l((_vm.info),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(item.name))])])}),0)]},proxy:true}],null,false,3127325837)}):_vm._e()]],2),(_vm.showConfigDialog)?_c('s-quick-config-dialog',{attrs:{"model":_vm.modelConfig},on:{"apply":function($event){return _vm.setObject($event)}},model:{value:(_vm.showConfigDialog),callback:function ($$v) {_vm.showConfigDialog=$$v},expression:"showConfigDialog"}}):_vm._e(),_c('v-row',{staticStyle:{"flex-wrap":"wrap"},attrs:{"no-gutters":""}},[_c('Widget',{ref:"widget",staticClass:"col-sm-7 col-12",attrs:{"height":_vm.height,"isLoading":!_vm.loaded,"chartOptions":_vm.chartOptions,"series":_vm.series,"type":"bar"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Готовность по направлениям "),_c('v-spacer'),(_vm.objects && _vm.objects.length > 1)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){_vm.showConfigDialog = true}}},[_vm._v("fas fa-wrench")]):_vm._e()]},proxy:true}])}),_c('Widget',{ref:"widget2",staticClass:"col-sm-5 col-12 pl-2",attrs:{"height":_vm.height,"isLoading":!_vm.loaded,"chartOptions":{
        chart: { height: _vm.height - 50, type: 'radialBar', offsetY: 0 },
        states: {
          hover: { filter: { type: 'none' } },
          active: { allowMultipleDataPointsSelection: false, filter: { type: 'none' } },
        },
        plotOptions: {
          radialBar: {
            hollow: { size: '60%' },
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              style: {
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: undefined,
              },
              name: {
                show: !false,
                color: undefined,
                offsetY: _vm.height * 0.35,
              },
              value: {
                show: true,
                color: '#333',
                offsetY1: _vm.height * 0.22,
                fontSize: '20px',
              },
            },
          },
        },
        stroke1: {
          dashArray: 1,
        },
        labels: [_vm.object.name || ''],
      },"series":[_vm.progress],"type":"radialBar"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Общая готовность")]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }