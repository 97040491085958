<template>
  <div>
    <popup-modal ref="popup">
      <template>
        <v-btn style="right: 12px; top: -12px; float: left" fab dark small color="blue-grey" title="Закрыть окно" @click="closePopup()">
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
        <v-simple-table v-if="info" dense style="max-height: 400px; min-width: 300px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Наименование</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in info" :key="i">
                <td>{{ item.name }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </popup-modal>
    <s-quick-config-dialog v-if="showConfigDialog" v-model="showConfigDialog" :model="modelConfig" @apply="setObject($event)"> </s-quick-config-dialog>

    <v-row no-gutters style="flex-wrap: wrap">
      <Widget class="col-sm-7 col-12" ref="widget" :height="height" :isLoading="!loaded" :chartOptions="chartOptions" :series="series" type="bar">
        <template v-slot:title>
          Готовность по направлениям
          <v-spacer />
          <v-icon v-if="objects && objects.length > 1" small @click="showConfigDialog = true">fas fa-wrench</v-icon>
        </template>
      </Widget>
      <Widget
        class="col-sm-5 col-12 pl-2"
        ref="widget2"
        :height="height"
        :isLoading="!loaded"
        :chartOptions="{
          chart: { height: height - 50, type: 'radialBar', offsetY: 0 },
          states: {
            hover: { filter: { type: 'none' } },
            active: { allowMultipleDataPointsSelection: false, filter: { type: 'none' } },
          },
          plotOptions: {
            radialBar: {
              hollow: { size: '60%' },
              startAngle: -135,
              endAngle: 135,
              dataLabels: {
                style: {
                  fontSize: '14px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 'bold',
                  colors: undefined,
                },
                name: {
                  show: !false,
                  color: undefined,
                  offsetY: height * 0.35,
                },
                value: {
                  show: true,
                  color: '#333',
                  offsetY1: height * 0.22,
                  fontSize: '20px',
                },
              },
            },
          },
          stroke1: {
            dashArray: 1,
          },
          labels: [object.name || ''],
        }"
        :series="[progress]"
        type="radialBar"
      >
        <template v-slot:title>Общая готовность</template>
      </Widget>
    </v-row>
  </div>
</template>

<script>
import { popupMenu } from "@/components/mixings";
export default {
  mixins: [popupMenu],
  components: {
    Widget: () => import("./widgets/chart"),
  },
  props: {
    height: { type: Number, default: 0 },
    config: { type: Object, default: () => {} },
  },
  data() {
    return {
      showConfigDialog: false,
      info: null,
      loaded: false,
      details: [],
      series: [],
      object: {},
      objects: [],
      progress: 0,
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
          toolbar: {
            show: !true,
          },
          zoom: {
            enabled: !true,
          },
          events: {
            dataPointSelection: (event, chartContext, { seriesIndex, dataPointIndex, config, w }) => {
              const detail = w.config.xaxis.details;
              const cat = w.config.xaxis.categories[dataPointIndex];
              this.info = [...(detail.filter(d => seriesIndex !== d.type && d.department == cat) || [])];
              if (this.info.length) this.$refs.popup.open();
              return;
              let res = `${cat} :`;
              detail
                .filter(d => seriesIndex !== d.type)
                .forEach(d => {
                  if (d.department == cat) {
                    res += (res ? "<br>" : "") + `${d.name}: ${d.value}`;
                  }
                });
              // this.info = `<div style="max-height:300px; max-width: 300px" class="tooltip" style="margin:10px">${res}</div>`;
              this.info = [...(detail.filter(d => seriesIndex !== d.type && d.department == cat) || [])];
              this.$refs.popup.open();
              // Выводим дополнительную информацию
              //document.getElementById("info").innerText = `<div style="max-height:300px; max-width: 300px" class="tooltip" style="margin:10px">${res}</div>`;
            },
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        states: {
          hover: { filter: { type: "none" } },
          active: { allowMultipleDataPointsSelection: false, filter: { type: "none" } },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: !true,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          details: [],
        },
        yaxis: {
          details: [],
        },
        tooltip: {
          enabled: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const detail = w.config.xaxis.details;
            const cat = w.config.xaxis.categories[dataPointIndex];
            const count = detail.filter(d => seriesIndex !== d.type && d.department == cat).length;
            if (!count) return "";
            return `<div style="max-height:300px; max-width: 300px" class="tooltip ma-3" style="margin:10px">Нажмите для просмотра (${count} записей)</div>`;
            let res = `${cat} :`;
            detail
              .filter(d => seriesIndex !== d.type)
              .forEach(d => {
                if (d.department == cat) {
                  res += (res ? "<br>" : "") + `${d.name}: ${d.value}`;
                }
              });
            return `<div style="max-height:300px; max-width: 300px" class="tooltip" style="margin:10px">${res}</div>`;
          },
        },
        legend: {
          show: false,
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },
  created() {
    this.getObjects();
  },
  computed: {
    modelConfig() {
      const options = this.objects.map(o => {
        return { value: o.id, text: o.name };
      });
      return [{ name: "id", type: "select", options, autocomplete: true }];
    },
  },
  methods: {
    setObject(e) {
      this.object = this.objects.find(o => o.id == e.id);
      console.log(e, this.object);
      this.loaded = !true;
      this.fitchData();
    },
    async getObjects() {
      let res = await this.$axios.get("/mechti/objects", { params: { filters: { status: 10 } } });
      let data = res?.data?.data || [];
      this.objects = data.map(o => {
        return { id: o.id, name: o.name };
      });
      this.object = this.objects?.[0];
      this.fitchData();
    },
    closePopup() {
      this.$refs.popup.close();
      this.info = null;
    },
    async fitchData() {
      if (!this.object) return;
      let res = await this.$axios.get("/mechti/construction-schedule", {
        params: {
          filters: {
            object_id: this.object.id,
            status: { condition: ">", value: 0 },
          },
        },
      });
      //[281, 203, 204].includes(d.status)
      const s1 = { Стройка: [], Дизайнер: [], Снабжение: [], Мебель: [], Комплектация: [] };
      const s0 = { Стройка: [], Дизайнер: [], Снабжение: [], Мебель: [], Комплектация: [] };
      let series = [
        { name: "Стройка", data: [] },
        { name: "Дизайнер", data: [] },
        { name: "Снабжение", data: [] },
        { name: "Мебель", data: [] },
        { name: "Комплектация", data: [] },
      ];
      let data = res.data.data;
      res = await this.$axios.get("/mechti/construction-goods", {
        params: {
          filters: {
            object_id: this.object.id,
            status: 0,
          },
        },
      });
      let dataB = res.data.data;
      let details = [];
      for (let i = 0; i < data.length; i++) {
        let el = data[i];
        if (el.status == 102) s1[el.department].push(el.work_days);
        else s0[el.department].push(el.work_days);
        details.push({ department: el.department, name: el.name, value: el.work_days, amount: el.work_days, type: el.status == 102 ? 1 : 0 });
      }
      for (let i = 0; i < dataB.length; i++) {
        let el = dataB[i];
        if ([299, 298].includes(el.tasks_status)) continue;
        if ([281, 203, 204].includes(el.tasks_status)) s1["Снабжение"].push(1);
        else s0["Снабжение"].push(1);
        details.push({ department: "Снабжение", name: el.name, value: 1, amount: el.amount, type: [281, 203, 204].includes(el.tasks_status) ? 1 : 0 });
        if (![281, 203, 204].includes(el.tasks_status)) console.log(el);
      }
      //console.log(details.filter(d => d.department == "Снабжение"));
      let ss = [];
      /*
      let maxL2 = 0;
      for (const key in s2) {
        maxL2 = Math.max(maxL2, s2[key].length);
      }
      console.log("maxl", maxL2);
*/
      let nn = [];
      for (let i = 0; i < series.length; i++) {
        let n = s1[series[i].name].reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        nn.push(n);
      }
      ss.push({ name: "compleate", data: nn, color: "#388E3C" });
      nn = [];
      for (let i = 0; i < series.length; i++) {
        let n = s0[series[i].name].reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        nn.push(n);
      }
      ss.push({ name: "uncompleate", data: nn, color: "#FFCDD2" });

      /*
      for (let i = 0; i < maxL1; i++) {
        let nn = [];
        for (let ii = 0; ii < series.length; ii++) {
          nn.push(s1[series[ii].name]?.[i] || null);
        }
        ss.push({ name: i + maxL2, data: nn, color: "#EE6D7A" });
      }*/
      this.series = ss;
      this.details = details;
      this.chartOptions.title = {
        text: this.object.name,
      };
      this.chartOptions.xaxis = {
        details: details,
        series1: ss,
        labels: {
          show: true,
        },
        categories: series.map(s => s.name),
      };

      this.chartOptions.yaxis = {
        show1: !false,
      };

      this.progress = ((details.reduce((a, c) => a + (c.type == 1 ? c.value : 0), 0) / details.reduce((a, c) => a + c.value, 0)) * 100).toFixed(0);
      this.loaded = true;
    },
  },
};
</script>
